import EventEmitter from 'wolfy87-eventemitter'

const ee = new EventEmitter()

export const EVENT_TIP = 'EVENT_TIP'
export const EVENT_CONFIRM = 'EVENT_CONFIRM'
export const EVENT_USER_INVALID = 'EVENT_USER_INVALID'
export const EVENT_USER_LOGIN = 'EVENT_USER_LOGIN'
export const EVENT_ALLOW_RENT = 'EVENT_ALLOW_RENT'

export default ee