export const SiteType = 'IT'
export const LOGIN_PARAMS_DEV = {
    url: {
        WECHAT_LOGIN_URL: 'https://reg-saas.whweidu.com/thirdparty/wechat/bind/login.do',
        QQ_LOGIN_URL: 'https://reg-saas.whweidu.com/thirdparty/qq/bind/login.do'
    },
    params: {
        companyId: '3f8c4fcb407645d98792cf4785f16205',
        appKey: '0331bf6ae2bc4c1db0740165889e3c6a',
        appSecret: '',
        appType: 1,
        appChannel: ''
    }
}
export const LOGIN_PARAMS_PRO = {
    url: {
        WECHAT_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/wechat/bind/login.do',
        QQ_LOGIN_URL: 'https://reg-saas.shengtian.com/thirdparty/qq/bind/login.do'
    },
    params: {
        companyId: '024ce752b4c747f0b9eb9f65e709416d',
        appKey: '9f9c4acf78414086992ea28e60bac3a3',
        appSecret: '',
        appType: 1,
        appChannel: ''
    }
}
const _url = location.protocol + '//' + location.host
export const BaseUrl = process.env.BUILD_ENV === 'pro'
    ? _url + '/api'
    : `http://test-${SiteType.toLowerCase()}.steamcdk.com/api`
export const ImgUrl = process.env.BUILD_ENV === 'pro'
    ? 'https://paras-img.shengtian.com' // 'https://dev-img.youxicdk.com'
    : 'https://dev-img.steamcdk.cn'
export const ShareDomains = process.env.BUILD_ENV === 'pro'
    ? (function getShareDomainsByLocation() {
        const la = location.host.split('.')
        la[la.length - 1] = la[la.length - 1] === 'com' ? 'hk' : 'com'
        return [location.protocol + '//' + la.join('.')]
        // const domains = [location.protocol + '//' + la.join('.')]
        // la.pop()
        // if (la.length === 2) {
        //     if (la[0] === 'www') {
        //         la.shift()
        //     } else if (la[0].indexOf('www-') > -1) {
        //         la[0] = la[0].replace('www-', '')
        //     } else {
        //         la[0] = 'www-' + la[0]
        //     }
        // } else if (la.length === 1) {
        //     la.unshift('www')
        // }
        // domains.push(location.protocol + '//' + la.join('.') + '.cn')
        // domains.push(location.protocol + '//' + la.join('.') + '.asia')
        // return domains
    })()
    : _url.indexOf('-cn') > 0
        ? [_url.replace('-cn', '-hk')]
        : [_url.replace('-hk', '-cn')]
// export const platformId = process.env.NODE_ENV === 'development' ? 30 : 30
export const platformName = 'pc'
export const PayTypeMap = { 1: '支付宝', 12: '支付宝', 2: '微信', 20: 'QQ', 23: 'PayerMax' }
export const PayerMaxPayType = 23
export const PayTime = 120
export const OrderStatusMap = { 1: '待支付', 2: '发货中', 12: '发货中', 3: '已退款', 5: '退款中', 11: '订单完成' }
export const BBOrderStatusMap = { 1: '待支付', 2: '待领取CDK', 12: '待领取CDK', 3: '已退款', 5: '退款中', 11: '发货成功', 99: '发货中' }
export const ServiceUrl = 'https://cs.numcsc.com/chat/pc/index.html?sysNum=20dce2c6fa909a5cd62526615f27e88a&visitEntrance=www.steamcdk.cn&robotFlag=1&remark=pc'
export const ServiceFor9891Url = 'http://q.url.cn/CDOGXS?_type=wpa&qidian=true'
export const RentWayMap = { 10: '小时', 20: '天', 30: '晚', 40: '周' }
export const RentOrderStatusMap = { 10: '待支付', 30: '待发货', 40: '租赁中', 50: '订单完成', 52: '订单完成', 60: '已退款', 70: '维权中' }
export const DefaultChannel = '100000'
export const ShowHKD = SiteType === 'HK'
export const MName = '游特卖'