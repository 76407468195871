import { SiteType, BaseUrl, ImgUrl, platformName, ServiceUrl } from '../../config'
import ee, { EVENT_USER_INVALID, EVENT_TIP } from './event'
import Locale from '../../config/locale'
// import Translater from './ToolGood.Words.Translate'

export const generateGUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
    })
}

export const isString = s => typeof s === 'string'

export const isFunc = f => typeof f === 'function'

export const isNumber = n => typeof n === 'number'

export const safeParse = (json, defaultValue = null) => {
    if (!isString(json) || !json.length) {
        return defaultValue
    }

    try {
        return JSON.parse(json)
    } catch (e) {
        console.error(e, json)
        return defaultValue
    }
}

const createFormData = (data) => {
    const formdata = new FormData()
    if (data.file && data.filename) {
        formdata.append('file', data.file, data.filename)
        delete data.file
        delete data.filename
    }
    Object.keys(data).forEach(k => {
        formdata.append(k, data[k])
    })
    return formdata
}

export const transformQueryString = (data, forBody = false) => {
    const keys = Object.keys(data)
    return keys.length ? `${forBody ? '' : '?'}${keys.map(k => `${k}=${data[k] === void 0 ? '' : encodeURIComponent(data[k] + '')}`).join('&')}` : ''
}

export const parseQueryString = query => {
    const ret = Object.create(null)
    if (query) {
        query.substring(1).split('&').forEach(pair => {
            const [k, v] = pair.split('=')
            ret[k] = decodeURIComponent(v)
        })
    }
    return ret
}

export const getCurrentDomain = () => {
    return window.location.origin || `${window.location.protocol}//${window.location.host}`
}

const _uid_cache_key = '_guck'
const _token_cache_key = '_gtck'
const _phone_cache_key = '_gpck'
const _nickname_cache_key = '_gnck'
const _channel_cache_key = '_gcck'
const _age_cache_key = '_gack'
const _auth_cache_key = '_grck'
const _ucid_cache_key = '_gick'

export const saveChannelId = cid => {
    localStorage.setItem(_channel_cache_key, cid)
}

export const getLocalChannelId = () => {
    return localStorage.getItem(_channel_cache_key)
}

export const saveUserInfoLocally = ({ uid, token, phone, displayName, age, isAuth, ucuid }) => {
    localStorage.setItem(_uid_cache_key, uid + '')
    localStorage.setItem(_token_cache_key, token)
    localStorage.setItem(_phone_cache_key, phone)
    localStorage.setItem(_nickname_cache_key, displayName)
    localStorage.setItem(_age_cache_key, age + '')
    localStorage.setItem(_auth_cache_key, isAuth + '')
    localStorage.setItem(_ucid_cache_key, ucuid + '')
    return transformQueryString({ uid, token, phone, displayName, age, isAuth, ucuid })
}

export const clearUserInfoLocally = () => {
    localStorage.removeItem(_uid_cache_key)
    localStorage.removeItem(_token_cache_key)
    localStorage.removeItem(_phone_cache_key)
    localStorage.removeItem(_nickname_cache_key)
    localStorage.removeItem(_age_cache_key)
    localStorage.removeItem(_auth_cache_key)
    localStorage.removeItem(_ucid_cache_key)
}

export const checkUserInfoLocally = () => {
    const uid = localStorage.getItem(_uid_cache_key)
    const token = localStorage.getItem(_token_cache_key)
    const phone = localStorage.getItem(_phone_cache_key)
    const displayName = localStorage.getItem(_nickname_cache_key)
    if (!uid || !token || !phone || !displayName) {
        return null
    }
    const age = Number(localStorage.getItem(_age_cache_key)) || 0
    const isAuth = Number(localStorage.getItem(_auth_cache_key)) || 0
    return {
        uid: Number(uid),
        token,
        phone,
        displayName,
        age,
        isAuth
    }
}

export const updateCertInfoLocally = (isAuth, age) => {
    localStorage.setItem(_age_cache_key, age + '')
    localStorage.setItem(_auth_cache_key, isAuth + '')
}

export const getFromLocalStorage = key => {
    return safeParse(localStorage.getItem(key))
}

export const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, isString(value) ? value : JSON.stringify(value))
}

export const removeFromLocalStorage = key => {
    localStorage.removeItem(key)
}

export const keyboardEnter = (e, action) => {
    if (e.keyCode === 13) {
        e.preventDefault()
        action()
    }
}

const opRequestParams = (url, data = {}, headers = {}, auth = false) => {
    url.indexOf('http') !== 0 && (url = BaseUrl + url)
    if (auth) {
        const uid = localStorage.getItem(_uid_cache_key)
        const token = localStorage.getItem(_token_cache_key)
        headers.uid = data.uid = Number(uid)
        headers.Authorization = token
    }
    // headers.siteType = SiteType
    data.cid = headers.cid = localStorage.getItem(_channel_cache_key)
    // data.platform = headers.platform = platformName
    return {
        url, data, headers
    }
}

const opResponse = (response, silent) => {
    const status = response.status
    if ((status >= 200 && status < 300) || status === 304) {
        return response.json().then(result => {
            const { code, message, data } = result
            if (code === 200) {
                return data
            } else if (code === 401 && !silent) {
                ee.emit(EVENT_USER_INVALID)
                ee.emit(EVENT_TIP, '登录失效，请重新登录')
            }
            const e = new Error(message)
            e.code = code
            throw e
        })
    } else {
        throw new Error(response.statusText)
    }
}

export const httpGet = ({ url: up_url, data: up_data, headers: up_headers, auth, silent = false }) => {
    const { url, data, headers } = opRequestParams(up_url, up_data, up_headers, auth)
    return fetch(url + transformQueryString(data), {
        method: 'GET',
        headers: {
            ...headers
        }
    }).then(res => opResponse(res, silent))
}

export const httpPost = ({ url: up_url, dataType = 'xwf', data: up_data, headers: up_headers, auth, silent = false }) => {
    const { url, data, headers } = opRequestParams(up_url, up_data, up_headers, auth)
    return fetch(url, {
        method: 'POST',
        headers: {
            ...headers,
            ...dataType !== 'formData' && {
                'Content-Type': dataType === 'json'
                    ? 'application/json'
                    : 'application/x-www-form-urlencoded'
            }
        },
        // mode: 'cors',
        body: dataType === 'xwf'
            ? transformQueryString(data, true)
            : dataType === 'json'
                ? JSON.stringify(data)
                : createFormData(data)
    }).then(res => opResponse(res, silent))
}

export const hbsBoolean = (v) => {
    if (v === null || v === void 0 || v === false || v === '' || v === 0 || (Array.isArray(v) && !v.length)) {
        return false
    }
    return true
}

export const getImgUrl = (url) => {
    url = url || ''
    return url.indexOf('http') !== 0 ? ImgUrl + url : url
}

export const getLinkUrl = (function () {
    const channel = window._page_channel
    let func = url => url
    if (channel) {
        func = url => {
            if ((url.indexOf('/') <= 0 || url.indexOf('youxicdk.') > -1) && !/[pc]id=/.test(url)) {
                return url + (url.indexOf('?') > 0 ? '&pid=' : '?pid=') + channel
            }
            return url
        }
    }
    return func
})()

const createHiddenInput = (name, value) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    return input
}

export const createNativeForm = ({ action, method = 'post', enctype = 'application/x-www-form-urlencoded', data }) => {
    const id = '_nfref'
    const exist = document.getElementById(id)
    if (exist) {
        exist.remove()
    }
    const form = document.createElement('form')
    form.id = id
    form.action = action
    form.method = method
    form.enctype = enctype
    Object.keys(data).forEach(k => {
        form.appendChild(createHiddenInput(k, data[k] + ''))
    })
    document.body.appendChild(form)
    return form
}

export const showAmount = a => {
    return Number(a).toFixed(2)
}

export const showDiscount = (p, op) => {
    let discount = Math.round((op - p) * 100 / op)
    if (discount > 99) {
        discount = 99
    } else if (discount < 1) {
        discount = 1
    }
    return `-${discount}%`
}

export const throttle = (fn, interval = 333) => {
    let timerId

    return function () {
        if (!timerId) {
            const args = arguments
            timerId = setTimeout(() => {
                fn.apply(this, args)
                timerId = null
            }, interval)
        }
    }
}

export const opUrlByType = item => {
    if (item.urlType === 3) {
        item.urlType = 1
        item.url = `/app/${item.url}/${item.ename ? item.ename.replace(/\s/g, '_') : '_'}/`
    } else if (item.urlType === 1 && item.url.indexOf('/') !== 0 && item.url.indexOf('http') !== 0) {
        item.url = '/' + item.url
    }
    return item
}

export const getPLink = p => {
    return `/app/${p.goodsId}/${p.ename ? p.ename.replace(/\s/g, '_') : '_'}/`
}

export const opRentTags = item => {
    if (item.loginMethod === 1) {
        item.isPassword = true
    } else if (item.loginMethod === 2) {
        item.isToken = true
    }
    if (item.userIdentity === 1) {
        item.isBusiness = true
    }
    if (item.discountInfo) {
        item.discountTag = item.discountInfo.map(d => `租${d.buy}送${d.free}`).join(' / ')
    }
    return item
}

export const formatTimeBySeconds = seconds => {
    let s = seconds % 60
    s < 10 && (s = '0' + s)
    s += '秒'
    seconds = Math.floor(seconds / 60)
    if (!seconds) {
        return s
    }
    let m = seconds % 60
    m < 10 && (m = '0' + m)
    m += '分' + s
    seconds = Math.floor(seconds / 60)
    if (!seconds) {
        return m
    }
    return seconds + '小时' + m
}

export const parseDateToTimestamp = dateStr => {
    const arr = dateStr.split(' ')
    const dateArr = arr[0].split('-').map(n => parseInt(n, 10))
    const timeArr = arr[1].split(':').map(n => parseInt(n, 10))
    return new Date(dateArr[0], dateArr[1] - 1, dateArr[2], timeArr[0], timeArr[1], timeArr[2]).getTime()
}

export const formatTimeForSeckill = seconds => {
    const result = {
        hour: '00',
        min: '00',
        sec: '00'
    }
    let s = seconds % 60
    result.sec = (s < 10 ? '0' : '') + s
    seconds = Math.floor(seconds / 60)
    if (!seconds) {
        return result
    }
    let m = seconds % 60
    result.min = (m < 10 ? '0' : '') + m
    seconds = Math.floor(seconds / 60)
    if (!seconds) {
        return result
    }
    let h = seconds % 24
    result.hour = (h < 10 ? '0' : '') + h
    return result
}

export const getUnitForSeckill = unit => {
    const info = {
        0: {
            label: '秒',
            value: 1000
        },
        1: {
            label: '分钟',
            value: 60000
        },
        2: {
            label: '小时',
            value: 60000 * 60
        }
    }
    return info[unit] || info[2]
}

export const checkCouponStatus = (coupon, isForP) => {
    let statusClass = 'normal'
    const { userCouponInfos, couponStock, userReceiveNumLimit } = coupon
    const hasTotal = Array.isArray(userCouponInfos) ? userCouponInfos.length : 0
    const hasNum = hasTotal > 0 ? userCouponInfos.filter(c => c.couponItemStatus === 1).length : 0
    const canReceive = couponStock > 0 && hasTotal < userReceiveNumLimit
    if (hasNum) {
        if (isForP || !canReceive) {
            statusClass = 'use'
        }
    } else if (!canReceive) {
        statusClass = 'disabled'
    }
    coupon.statusClass = statusClass
    return coupon
}

export const sortCouponList = couponList => {
    const o = []
    const d = []
    couponList.forEach(c => {
        (c.statusClass === 'disabled' ? d : o).push(c)
    })
    return o.concat(d)
}

export const getCouponDate = dateStr => {
    return dateStr ? dateStr.split(' ')[0] : ''
}

export const getCouponUrl = coupon => {
    if (coupon.effectiveRangeType === 0 || !Array.isArray(coupon.goodsList) || !coupon.goodsList.length) {
        return '/'
    }
    if (coupon.goodsList.length === 1) {
        return `/p/${coupon.goodsList[0].goodId}.html`
    }
    return `/coupon/prdList.html?couponId=${coupon.id}`
}

export const getCouponDateStr = coupon => {
    if (coupon.effectType === 1) {
        return `${getCouponDate(coupon.effectStartTime)}至${getCouponDate(coupon.effectEndTime)}`
    } else if (coupon.effectType === 2) {
        return `领券当日起${coupon.effectDays}天内可用`
    } else if (coupon.effectType === 3) {
        return `领券次日起${coupon.effectDays}天内可用`
    }
    return ''
}

export const computeDiscountPrice = (amount, discount) => {
    return (((amount * 100) - (discount * 100)) / 100).toFixed(2)
}

export const computeOriginalPrice = (amount, discount) => {
    return (((amount * 100) + (discount * 100)) / 100).toFixed(2)
}

export const setCookie = (key, value) => {
    document.cookie = `${key}=${value}`
}

export const getCookie = key => {
    const ps = document.cookie.split('; ')
    for (let i = 0; i < ps.length; ++i) {
        let kv = ps[i].split('=')
        if (kv[0] === key) {
            return kv[1]
        }
    }
    return ''
}

export const delCookie = key => {
    document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT`
}

export const showDiscountRate = percent => {
    return (percent / 10).toFixed(1).replace('.0', '')
}

export const goToHref = (href, target = '_self') => {
    if (target === '_blank') {
        window.open(href)
    } else if (target === '_self') {
        location.href = href
    }
}

export const getBlindboxQuality = bb => {
    bb.quality = { 1: 'green', 2: 'blue', 3: 'purple', 4: 'red', 5: 'yellow' }[bb.borderColor] || 'green'
}

export const parseCDKs = cdks => {
    const showCDKContent = []
    const copyCDKContent = []
    cdks.forEach(item => {
        let cdk = safeParse(item, item)
        if (isNumber(cdk)) {
            cdk += ''
        }
        if (isString(cdk)) {
            showCDKContent.push([{ header: null, content: cdk }])
            copyCDKContent.push(cdk)
        } else {
            let arr = []
            Object.keys(cdk).forEach(header => {
                arr.push({ header, content: cdk[header] })
                copyCDKContent.push(header + '：' + cdk[header])
            })
            showCDKContent.push(arr)
        }
    })
    return {
        showCDKContent,
        copyCDKContent
    }
}

export const isIE = () => {
    const ua = navigator.userAgent
    return /rv\:\d+/.test(ua) || /MSIE/.test(ua)
}

export const isTransformSupported = () => {
    return 'transform' in document.documentElement.style
}

export const getReportParams = () => {
    const params = {
        os_type: 1,
        ua: navigator.userAgent,
        show_url: location.href,
        refer_url: document.referrer
    }
    const sys_info = safeParse(localStorage.getItem('_tj_sysinfo'), {
        BootId: '',
        ComputerName: '',
        MACAddress: '',
        NetBarGid: ''
    })
    params.gid = sys_info.NetBarGid
    params.mac = sys_info.MACAddress
    params.pcname = sys_info.ComputerName
    params.bootid = sys_info.BootId
    return params
}

export const i18n = text => {
    const resource = Locale[text]
    if (!resource) {
        return ''
    }
    const content = resource[window.__lang]
    return content === null ? '' : content || resource['en']
}

export const i18n_style = (en_style, other_style) => {
    return window.__lang === 'en' ? en_style : other_style
}

export const getServiceUrl = user => {
    const { isLogin, uid, phone, displayName } = user || {}
    return isLogin === 1 ? `${ServiceUrl}&tel=${phone}&partnerId=${uid}&realname=${displayName}` : ServiceUrl
}

export const isValidEmail = email => {
    return typeof email === 'string' && /^[a-zA-Z\d]+[a-zA-Z\d\._-]*@[a-zA-Z\d]+\.[a-zA-Z\d\._-]*[a-zA-Z\d]+$/.test(email)
}