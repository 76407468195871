import { httpGet } from '../../common/util'

export const subscribe = email => {
    return httpGet({
        url: '/web/email/submit',
        data: {
            email,
            lang: window.__lang
        }
    })
}