import React from 'react'
import Portal from '../portal'
import { subscribe } from './service'
import { i18n, isValidEmail } from '../../common/util'
import ee, { EVENT_TIP, EVENT_CONFIRM } from '../../common/util/event'

class Footer extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            email: '',
            studioEmail: window._inject_app_data_.studioEmail
        }
        this.submitFlag = false
    }

    inputEmail = e => {
        this.setState({
            email: e.target.value
        })
    }

    submit = () => {
        if (this.submitFlag) {
            return
        }
        const { email } = this.state
        if (!isValidEmail(email)) {
            return ee.emit(EVENT_TIP, '_invalid_email')
        }
        this.submitFlag = true
        subscribe(email).then(() => {
            ee.emit(EVENT_CONFIRM, '_subscribe_success')
        }).catch(e => {
            ee.emit(EVENT_TIP, '_submit_failed')
            console.error(e)
        }).finally(() => {
            this.submitFlag = false
        })
    }

    render() {
        return <div className="G-footer-content">
            <div className="G-footer-block">
                <a target="_self" href="/">
                    <div className="G-footer-logo-wrapper"></div>
                </a>
            </div>
            <div className="G-footer-block">
                <div className="G-footer-form-wrapper">
                    <p className="label">{i18n('_news_letter')}:</p>
                    <div className="form">
                        <input type="text" name="footer-email" className="input" value={this.state.email} onChange={this.inputEmail} placeholder={i18n('_fill_email')} />
                        <button className="submit" onClick={this.submit}>{i18n('_subscribe')}</button>
                    </div>
                </div>
            </div>
            <div className="G-footer-block">
                <ul>
                    <a target="_self" href="/">
                        <li className="G-footer-nav">{i18n('_games')}</li>
                    </a>
                    <li className="G-footer-split"></li>
                    <a target="_self" href="/services">
                        <li className="G-footer-nav">{i18n('_services')}</li>
                    </a>
                    <li className="G-footer-split"></li>
                    <a target="_self" href="/intro">
                        <li className="G-footer-nav">{i18n('_about_us')}</li>
                    </a>
                    <li className="G-footer-split"></li>
                    <a target="_self" href="/submit-games">
                        <li className="G-footer-nav">{i18n('_submit_games')}</li>
                    </a>
                    <li className="G-footer-split"></li>
                    <a href={`mailto:${this.state.studioEmail}`}>
                        <li className="G-footer-nav last">{i18n('_contact_us')}</li>
                    </a>
                </ul>
                <ul className="G-footer-tul">
                    <a target="_blank" href="https://twitter.com/paras_games">
                        <li className="G-footer-tl twitter"></li>
                    </a>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100084781574949">
                        <li className="G-footer-tl facebook"></li>
                    </a>
                    <a target="_blank" href="https://store.steampowered.com/curator/42071800">
                        <li className="G-footer-tl steam"></li>
                    </a>
                </ul>
                <p className="G-footer-info copyright">&copy;All Rights Reserved Paras Studio 2021 | Terms & Privacy</p>
                <p className="G-footer-info">Site Build by Code32 | Site Design & Branding by Fully Illustrated</p>
            </div>
        </div>
    }
}

export default Portal(Footer, 'G-footer-container')