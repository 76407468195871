import React from 'react'
import ee, { EVENT_TIP, EVENT_CONFIRM } from '../../common/util/event'
import './index.scss'
import { i18n } from '../../common/util'

class GTip extends React.Component {
    constructor() {
        super(...arguments)
        this.state = {
            tip: null,
            confirm: null
        }
        this.timer = null
    }

    componentDidMount() {
        ee.addListener(EVENT_TIP, message => {
            if (this.timer) {
                clearTimeout(this.timer)
            }
            this.timer = setTimeout(() => {
                this.timer = null
                this.setState({ tip: null })
            }, 3000)
            this.setState({ tip: message })
        })
        ee.addListener(EVENT_CONFIRM, message => {
            this.setState({ confirm: message })
        })
    }

    closeConfirm = () => {
        this.setState({
            confirm: null
        })
    }

    render() {
        const { tip, confirm } = this.state
        return <div>
            {
                tip && <div className='g-tip-container'>{i18n(tip)}</div>
            }
            {
                confirm && <div className='g-confirm-container'>
                    <div className='g-confirm'>{i18n(confirm)}<i className='g-confirm-close' onClick={this.closeConfirm}></i></div>
                </div>
            }
        </div>
    }
}

export default GTip