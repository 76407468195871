const Locale = {
    '_games': {
        en: 'GAMES',
        zh_CN: '全部游戏',
        zh_HK: '全部遊戲'
    },
    '_services': {
        en: 'SERVICES',
        zh_CN: '合作服务',
        zh_HK: '合作服務'
    },
    '_about_us': {
        en: 'ABOUT US',
        zh_CN: '关于我们',
        zh_HK: '關於我們'
    },
    '_submit_games': {
        en: 'SUBMIT GAMES',
        zh_CN: '提交游戏',
        zh_HK: '提交遊戲'
    },
    '_news_letter': {
        en: 'NEWSLETTER',
        zh_CN: '新闻订阅',
        zh_HK: '新聞訂閲'
    },
    '_fill_email': {
        en: 'Please fill in your email',
        zh_CN: '请填写邮箱',
        zh_HK: '請填寫郵箱'
    },
    '_subscribe': {
        en: 'SUBSCRIBE',
        zh_CN: '提交',
        zh_HK: '提交'
    },
    '_contact_us': {
        en: 'CONTACT US',
        zh_CN: '联系我们',
        zh_HK: '聯係我們'
    },
    '_subscribe_success': {
        en: 'THANK YOU FOR YOUR SUBSCRIPTION!',
        zh_CN: '感谢您的订阅！',
        zh_HK: '感謝您的訂閲！'
    },
    '_invalid_email': {
        en: 'E-MAIL FORMAT IS NOT CORRECT',
        zh_CN: '电子邮件格式不正确',
        zh_HK: '電子郵件格式不正確'
    },
    '_first_name': {
        en: 'FIRST NAME',
        zh_CN: '名',
        zh_HK: '名'
    },
    '_last_name': {
        en: 'LAST NAME',
        zh_CN: '姓',
        zh_HK: '姓'
    },
    '_email': {
        en: 'EMAIL',
        zh_CN: '电子邮件',
        zh_HK: '電子郵件'
    },
    '_game_name': {
        en: 'GAME NAME',
        zh_CN: '游戏名称',
        zh_HK: '遊戲名稱'
    },
    '_game_description': {
        en: 'GAME DESCRIPTION',
        zh_CN: '游戏说明',
        zh_HK: '遊戲說明'
    },
    '_game_build_link': {
        en: 'GAME BUILD LINK',
        zh_CN: '游戏试玩链接',
        zh_HK: '遊戲試玩鏈接'
    },
    '_i_understand': {
        en: 'I understand that my personal information is only collected, used and disclosed by ParasStudios as permitted by law.',
        zh_CN: '我了解我的个人信息仅由ParasStudios在法律允许的情况下收集、使用和披露。',
        zh_HK: '我了解我的個人信息僅由ParasStudios在法律允許的情況下收集、使用和披露。'
    },
    '_submit': {
        en: 'SUBMIT',
        zh_CN: '提交',
        zh_HK: '提交'
    },
    '_required': {
        en: '* This field is required',
        zh_CN: '* 这是必填栏',
        zh_HK: '* 這是必填欄'
    },
    '_agreement': {
        en: 'Please read and agree to the privacy agreement',
        zh_CN: '请阅读并同意隐私协议',
        zh_HK: '請閱讀並同意隱私協議'
    },
    '_submit_failed': {
        en: 'FAILED. SORRY, PLEASE CONTACT THE LOWER RIGHT CONTACT US, WE WILL DEAL WITH IT LATER.',
        zh_CN: '提交失败。对不起，请联系右下方的联系我们，我们稍后会处理！',
        zh_HK: '提交失敗。對不起，請聯系右下方的聯系我們，我們稍後會處理！'
    },
    '_submit_success': {
        en: 'THANK YOU FOR YOUR GAME, WE WILL TRY IT LATER!',
        zh_CN: '感谢您的提交，稍后会给您回复！',
        zh_HK: '感謝您的提交，稍後會給您回復！'
    },
    '_more': {
        en: 'MORE',
        zh_CN: '更多',
        zh_HK: '更多'
    }
}

export default Locale